import Vue from "vue";
import Component from "vue-class-component";

@Component
export class HasObjectMethods extends Vue {
  /**
   * @description
   * Get the property value of an object based on the path provided.
   * When a . is detected in the path go a level deeper in the object.
   *
   * @returns {void}
   */
  getNestedValue(object: Object, path: any) {
    return path.split(".").reduce((acc: any, key: string) => (acc ? acc[key] : null), object);
  }
}
