import Vue from "vue";
import Component from "vue-class-component";
import * as PropertyEnums from "@/enums/property";
import { Mixins } from "vue-property-decorator";
import { HasEnumMethods } from "../has-enum-methods";

@Component
export class HasPropertyOptions extends Mixins(HasEnumMethods) {
  propertyTypeOptions = this.optionsFromEnum(PropertyEnums.PropertyType, "property.types");
  propertyBuildingTypeOptions = this.optionsFromEnum(PropertyEnums.PropertyBuildingType, "property.building_types");
  propertyBuildingStateOptions = this.optionsFromEnum(PropertyEnums.PropertyBuildingState, "property.building_state");
  propertyProjectVatOptions = this.optionsFromEnum(PropertyEnums.PropertyProjectVat, "property.project_vat");
  propertyGoalOptions = this.optionsFromEnum(PropertyEnums.PropertyGoal, "property.goals");
  propertyZoneOptions = this.optionsFromEnum(PropertyEnums.PropertyZones, "property.zones");
  propertyOrientationOptions = this.optionsFromEnum(PropertyEnums.Orientations, "property.orientations");
  propertyGScoreOptions = this.optionsFromEnum(PropertyEnums.propertyGScore);
  propertyPScoreOptions = this.optionsFromEnum(PropertyEnums.propertyPScore);
  propertyEpcLabelOptions = this.optionsFromEnum(PropertyEnums.propertyEpcLabel);

  // room options
  propertyRoomTypeOptions = this.optionsFromEnum(PropertyEnums.RoomTypes, "property.room.types");
  propertyRoomKitchenTypeOptions = this.optionsFromEnum(PropertyEnums.RoomKitchenTypes, "property.room.kitchen_types");
  propertyRoomBathroomTypeOptions = this.optionsFromEnum(PropertyEnums.RoomBathroomTypes, "property.room.bathroom_types");
  propertyRoomFloorOptions = [
    // not in enum because keys are not valid enum values
    { value: -1, label: "-1" },
    { value: 0, label: "0" },
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
  ];

  // TODO: Extract below options to separate enums
  propertyCentralizedHeatingOptions = [
    { value: "RADIATORS", label: "Radiatoren" },
    { value: "UNDERFLOOR", label: "Vloerverwarming" },
    { value: "ACCUMULATORS", label: "Accumulatoren" },
    { value: "AIR_CONDITIONER", label: "Airco" },
  ];

  propertyCentralizedHeatingFuelOptions = [
    { value: "GAS", label: "Aardgas" },
    { value: "ELECTRICITY", label: "Elektriciteit" },
    { value: "PROPANE", label: "Propaan" },
    { value: "OIL", label: "Stookolie" },
    { value: "HEAT_PUMP", label: "Warmtepomp" },
    { value: "PELLET", label: "Pellet" },
  ];

  propertyDeCentralizedHeatingOptions = [
    { value: "GAS", label: "Gas" },
    { value: "WOOD", label: "Hout" },
    { value: "PELLET", label: "Pellet" },
    { value: "ACCUMULATION", label: "Stookolie" },
  ];

  propertyWaterHeatingOptions = [
    { value: "COMBI", label: "Combi" },
    { value: "GAS", label: "Gas" },
    { value: "ELECTRICITY", label: "Elektriciteit" },
    { value: "OIL", label: "Stookolie" },
    { value: "SOLAR_BOILER", label: "Zonneboiler" },
    { value: "HEAT_PUMP_BOILER", label: "Warmtepompboiler" },
  ];

  propertyElectricalOptions = [
    { value: "SINGLE", label: "Enkel" },
    { value: "DOUBLE", label: "Dubbel" },
    { value: "TRIPLE", label: "Drievoudig" },
    { value: "DIGITAL", label: "Digitaal" },
  ];

  propertyVentilationOptions = [
    { value: "C", label: "C" },
    { value: "C_PLUS", label: "C+" },
    { value: "D", label: "D" },
    { value: "AIR_VENTS", label: "Luchtroosters" },
  ];

  propertyPriceOrRageOptions = [
    { label: "Eén prijs", value: "FIXED" },
    { value: "RANGE", label: "Prijs range" },
  ];

  propertyResidentAllowanceOptions = [
    { value: "MONTHLY", label: "Maandelijks" },
    { value: "DAILY", label: "Dagelijks" },
  ];

  propertyFacadeMaterialOptions = [
    { value: "STONE", label: "Baksteen" },
    { value: "STONE_STRIPS", label: "Steenstrips" },
    { value: "CREPI", label: "Crepi" },
    { value: "OTHER", label: "Ander" },
  ];

  propertyRoomMaterialOptions = [
    { value: "SLATE", label: "Leien" },
    { value: "REED", label: "Riet" },
    { value: "ASBESTOS", label: "Asbest" },
    { value: "SYNTHETIC", label: "Synthetisch" },
    { value: "PANS", label: "Dakpannen" },
    { value: "BITUMINOUS_ROOFING", label: "Bitumen" },
    { value: "METAL", label: "Metaal" },
    { value: "EPDM", label: "EPDM" },
    { value: "OTHER", label: "Ander" },
  ];

  propertyExteriorJoineryWindowOptions = [
    { value: "SINGLE", label: "Enkel" },
    { value: "DOUBLE", label: "Dubbel" },
    { value: "TRIPLE", label: "Driedubbel" },
  ];

  propertyExteriorJoineryMaterialOptions = [
    { value: "WOOD", label: "Hout" },
    { value: "PVC", label: "PVC" },
    { value: "ALUMINIUM", label: "Aluminium" },
  ];

  propertyRollerShuttersMaterialOptions = [
    { value: "ALUMINIUM", label: "Aluminium" },
    { value: "PVC", label: "PVC" },
    { value: "WOOD", label: "Hout" },
    { value: "OTHER", label: "Ander" },
  ];

  propertyGarageDoorMaterialOptions = [
    { value: "ALUMINIUM", label: "Aluminium" },
    { value: "PVC", label: "PVC" },
    { value: "WOOD", label: "Hout" },
    { value: "OTHER", label: "Ander" },
  ];

  propertyWallFinishingMaterialOptions = [
    { value: "WASHABLE_PAINT", label: "Afwasbare verf" },
    { value: "PAINT", label: "Verf" },
    { value: "CERAMIQUE", label: "Keramiek" },
    { value: "WOOD_PANELS", label: "Hout" },
    { value: "WALL_PAPER", label: "Behangpapier" },
    { value: "TERRACOTTA", label: "Terracotta" },
    { value: "STONE", label: "Steen" },
    { value: "PLASTERING", label: "Pleisterwerk" },
    { value: "PAINTED_PLASTERING", label: "Geschilderd pleisterwerk" },
    { value: "OTHER", label: "Ander" },
  ];

  propertyCeilingFinishingMaterialOptions = [
    { value: "WASHABLE_PAINT", label: "Afwasbare verf" },
    { value: "PAINT", label: "Verf" },
    { value: "STONE", label: "Steen" },
    { value: "PLASTERING", label: "Pleisterwerk" },
    { value: "PAINTED_PLASTERING", label: "Geschilderd pleisterwerk" },
    { value: "CERAMIQUE", label: "Keramiek" },
    { value: "WOOD_PANELS", label: "Hout" },
    { value: "TERRACOTTA", label: "Terracotta" },
    { value: "OTHER", label: "Ander" },
  ];

  propertyFloorFinishingMaterialOptions = [
    { value: "TILED", label: "Keramische tegels" },
    { value: "BAMBOU", label: "Bamboe" },
    { value: "BRICK", label: "Baksteen" },
    { value: "CARPET", label: "Tapijt" },
    { value: "CARPET_SQUARES", label: "Tapijttegels" },
    { value: "CONCRETE", label: "Beton" },
    { value: "CORK", label: "Kurk" },
    { value: "INDUSTRIAL", label: "Industrieel" },
    { value: "INDUSTRIAL_TILES", label: "industriële tegels" },
    { value: "LAMINATE", label: "Laminaat" },
    { value: "LINOLEUM", label: "Linoleum" },
    { value: "MARBLE", label: "Marmer" },
    { value: "NATURAL_STONE", label: "Natuursteen" },
    { value: "PARQUET", label: "Parket" },
    { value: "POLISHED_CONCRETE", label: "Gepolijst beton" },
    { value: "PVC", label: "PVC" },
    { value: "QUICKSTEP", label: "Quickstep" },
    { value: "RESIN", label: "Hars" },
    { value: "SCREED", label: "Zandcement" },
    { value: "SISAL", label: "Sisal" },
    { value: "VINYL", label: "Vinyl" },
    { value: "WOOD", label: "Hout" },
    { value: "OTHER", label: "Ander" },
  ];

  propertyMezzanineMaterialOptions = [
    { value: "WOOD", label: "Hout" },
    { value: "CONCRETE", label: "Beton" },
    { value: "OTHER", label: "Ander" },
  ];

  propertySinkTypeOptions = [
    { value: "SINGLE", label: "Enkel" },
    { value: "DOUBLE", label: "Dubbel" },
    { value: "ONE_AND_A_HALF", label: "Anderhalf" },
  ];

  propertyStoveTypeOptions = [
    { value: "INDUCTION", label: "Inductie" },
    { value: "CERAMIC", label: "Keramisch" },
    { value: "NATURAL_GAS", label: "Aardgas" },
  ];

  propertySalesPromiseAvailabilityOptions = [
    { value: "TBD_OWNER", label: "Af te spreken met eigenaar" },
    { value: "ON_DELIVERY", label: "Bij oplevering" },
    { value: "MUTUAL_AGREEMENT", label: "In onderling overleg" },
    { value: "PROVIDED_TENANTS_TAKEN_INTO_ACCOUNT", label: "Mits inachtneming huurders" },
    { value: "NOT_AVAILABLE", label: "Niet beschikbaar" },
    { value: "IMMEDIATE", label: "Onmiddellijk" },
    { value: "FROM_DEED", label: "Vanaf akte" },
  ];

  propertyHeatingTypeOptions = [
    { value: "INDIVIDUAL", label: "Individueel" },
    { value: "COLLECTIVE", label: "Collectief" },
  ];

  propertyFloodRiskOPeilOptions = [
    { value: "FLOOD_RISK", label: "Effectief overstromingsgevoelig gebied" },
    { value: "POTENTIAL_FLOOD_RISK", label: "Mogelijk overstromingsgevoelig gebied" },
    { value: "NO_FLOOD_RISK", label: "Niet gelegen in een overstromingsgevoelig gebied" },
  ];

  propertyFloodRisk2023Options = [
    { value: "A", label: "Klasse A (geen kans)" },
    { value: "B", label: "Klasse B (kleine kans onder klimaatverandering sc2050)" },
    { value: "C", label: "Klasse C (kleine kans onder huidig klimaat)" },
    { value: "D", label: "Klasse D (middelgrote kans onder huidig klimaat)" },
  ];

  propertyYesNoConformOption = [
    { label: "Ja, conform", value: "YES_CONFORM" },
    { label: "Ja, niet conform", value: "YES_NOT_CONFORM" },
    { label: "Neen", value: "NO" },
  ];

  propertyTerrainDestinationOptions = [
    { value: "AREA_WITH_ECONOMIC_ACTIVITIES", label: "gebied met economische activiteiten" },
    { value: "WINNING_AREA", label: "winningsgebied" },
    { value: "GROUND_RESERVE_AREA", label: "grondreservegebied" },
    { value: "FOREST_AREA", label: "bosgebied" },
    { value: "INDUSTRIAL_AREA", label: "industriegebied" },
    { value: "RECREATION_AREA_DAY", label: "gebied voor dagrecreatie" },
    { value: "RECREATION_AREA_RESIDENTIAL", label: "gebied voor verblijfsrecreatie" },
    { value: "MIXED_RESIDENTIAL_AREA", label: "gemengd woongebied" },
    { value: "NATURE_AREA", label: "natuurgebied" },
    { value: "PARK_AREA", label: "parkgebied" },
    { value: "RESIDENTIAL_AREA", label: "woongebied" },
    { value: "RESIDENTIAL_AREA_CULT_HIST_ESTHETIC", label: "woongebied met een cult., hist. en/of esthetische waarde" },
    { value: "RESIDENTIAL_AREA_RURAL_CHARACTER", label: "woongebied met landelijk karakter" },
    { value: "RESIDENTIAL_PARK", label: "woonpark" },
    { value: "RESIDENTIAL_EXPANSION_AREA", label: "woonuitbreidingsgebied" },
    { value: "GREEN_AREA", label: "groengebied" },
    { value: "NATURE_RESERVE", label: "natuurreservaat" },
    { value: "LANDSCAPE_VALUABLE_AGRICULTURAL_AREA", label: "landschappelijk waardevol agrarisch gebied" },
    { value: "INDUSTRIAL_AREA_CRAFT_ENTERPRISES", label: "industriegebied voor ambachtelijke bedrijven of gebieden voor kleine en middelgrote ondernemingen" },
    { value: "OTHER_GROUND_USAGES", label: "gebieden bestemd voor ander grondgebruik" },
    { value: "URBAN_DEVELOPMENT", label: "gebied voor stedelijke ontwikkeling" },
  ];

  propertyLegalSummonsOptions = [
    { value: "LEGAL_SUMMON_ISSUED", label: "dagvaarding uitgebracht" },
    { value: "JUDICIAL_RESTITUTION_IMPOSED", label: "rechterlijke herstelmaatregel opgelegd" },
    { value: "ADMINISTRATIVE_COERCION_IMPOSED", label: "bestuursdwang opgelegd" },
    { value: "INJUNCTION_UNDER_THREAT_OF_FINE_IMPOSED", label: "last onder dwangsom opgelegd" },
    { value: "ENTERED_VOLUNTARY_SETTLEMENT", label: "minnelijke schikking aangegaan" },
    { value: "NO_JUDICIAL_RESTITUTION_OR_ADMINISTRATIVE_MEASURE_IMPOSED", label: "geen rechterlijke herstelmaatregel of bestuurlijke maatregel opgelegd" },
  ];
}
