import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import type { PropertyInfoBlock } from "@/mixins/properties/has-config";

@Component({
  components: {
    PropertyPartGeneralHeader: () => import("@/views/auth/properties/parts/general/header.vue"),
    PropertyPartGeneralSubHeader: () => import("@/views/auth/properties/parts/general/sub-header.vue"),
  },
})
export class HasPropertyTabs extends Vue {
  selectedTab?: string = "building";

  tabOffsets: Array<{ id: string; offset: number }> = [];

  /**
   * Take the propertyTabs array and map it to return only the id,
   * title and icon properties of each object in the array.
   *
   */
  get tabs() {
    // @ts-ignore (Property tabs are invoked by a shared mixin: property/has-config)
    return (this.propertyTabs as PropertyInfoBlock[])
      .filter((f) => f.values.some((v) => v.allowed))
      .map((tab) => {
        if (!tab) {
          return;
        }

        return {
          id: tab.id,
          title: tab.title,
          icon: tab.icon,
        };
      });
  }

  /**
   * Take the selected tab based on the current # in the url, if none are found
   * Take either the first tab in the array or the building tab.
   *
   */
  @Watch("$route", { immediate: true })
  handleRouteChange() {
    let $routeTab = this.$route.hash.replace("#", "");

    this.selectedTab = $routeTab ? $routeTab : "building";
  }

  /**
   * Handle the scroll event on the scrollable element.
   * When the scroll position is greater than the offset of the tab
   * Highlight the current viewing tab.
   *
   */
  handleOnScroll() {
    const scrollEl = document.querySelector(".scrollable");

    if (!scrollEl) {
      return;
    }

    var scrollPosition = scrollEl.scrollTop;

    this.tabOffsets.forEach((tabOffset) => {
      // Add a 40% offset to the window height to make sure the tab is highlighted
      if (tabOffset.offset <= scrollPosition + window.innerHeight * 0.4) {
        this.selectedTab = tabOffset.id;
      }
    });
  }

  /**
   * Scroll to the selected tab after the component has been mounted.
   * And the form is generated.
   *
   */
  async handleTabsUpdate() {
    if (!this.selectedTab) {
      return;
    }

    await new Promise((resolve) => setTimeout(resolve, 500));

    let $tabGroup = this.$refs[this.selectedTab];

    let $tab = $tabGroup && Array.isArray($tabGroup) && $tabGroup.length ? $tabGroup[0] : null;

    if ($tab && $tab.hasOwnProperty("$el")) {
      //@ts-ignore (We can always assume that $el is available)
      $tab.$el.scrollIntoView({ behavior: "instant", block: "start", inline: "nearest" });
      Object.keys(this.$refs).forEach((key) => {
        //@ts-ignore (We can always assume that $el is available)
        let $el = this.$refs[key][0]?.$el;
        if (!$el) {
          return;
        }
        this.tabOffsets.push({ id: key, offset: $el.offsetTop });
      });
    }
  }
}
