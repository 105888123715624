export enum PropertyType {
  House = "HOUSE",
  Appartment = "APPARTMENT",
  Land = "LAND",
  Commercial = "COMMERCIAL",
  Parking = "PARKING",
}

export enum PropertyHouseSubTypes {
  House = "HOUSE",
  Villa = "VILLA",
  Bungalow = "BUNGALOW",
  MultiPurposeBuilding = "MULTI_PURPOSE_BUILDING",
  VillaFarm = "VILLA_FARM",
  Farm = "FARM",
  CountryHouse = "COUNTRY_HOUSE",
  Chalet = "CHALET",
  MaisonDeMaitre = "MAISON_DE_MAITRE",
  BelEtage = "BEL_ETAGE",
  FamilyHouse = "FAMILY_HOUSE",
  Cottage = "COTTAGE",
  Castle = "CASTLE",
}

export enum PropertyAppartmentSubTypes {
  Appartment = "APPARTMENT",
  GroundFlour = "GROUND_FLOOR",
  Duplex = "DUPLEX",
  Flat = "FLAT",
  Penthouse = "PENTHOUSE",
  Studio = "STUDIO",
  Loft = "LOFT",
  Triplex = "TRIPLEX",
  AssistantBuilding = "ASSISTANT_BUILDING",
  ServiceFlat = "SERVICEFLAT",
}

export enum PropertyCommercialSubTypes {
  MultiPurposeCommercialBuilding = "MULTI_PURPOSE_COMMERCIAL_BUILDING",
  Commercial = "COMMERCIAL",
  CommercialGroundFLoor = "COMMERCIAL_GROUND_FLOOR",
  Office = "OFFICE",
  IndividualShop = "INDIVIDUAL_SHOP",
  Industrial = "INDUSTRIAL",
  Storage = "STORAGE",
  IndustrialBuilding = "INDUSTRIAL_BUILDING",
}

export enum PropertyGroundSubTypes {
  BuildingGround = "BUILDING_GROUND",
  ProjectBuildingGround = "PROJECT_BUILDING_GROUND",
  Field = "FIELD",
  Wood = "WOOD",
  AgricultureLand = "AGRICULTURAL_LAND",
  GroundForEnterprises = "GROUND_FOR_ENTERPRISES",
}

export enum PropertyParkingSubTypes {
  OutsideParking = "OUTSIDE_PARKING",
  InsideParking = "INSIDE_PARKING",
  ClosedGarage = "CLOSED_GARAGE",
  DoubleGarage = "DOUBLE_GARAGE",
  GarageBox = "GARAGE_BOX",
  Carport = "CARPORT",
}

export enum PropertyGoal {
  NewConstruction = "NEW_CONSTRUCTION",
  Resell = "RESELL",
}

export enum PropertyProjectVat {
  Six = "6",
  TwentyOne = "21",
}

export enum PropertyBuildingType {
  OpenBebouwing = "OB",
  HalfOpenBebouwing = "HOB",
  GeslotenBebouwing = "GB",
}

export enum PropertyBuildingState {
  NewConstruction = "NEW_CONSTRUCTION",
  Luxurious = "LUXURIOUS",
  MoveInReady = "MOVE_IN_READY",
  LightRefresh = "LIGHT_REFRESH",
  ToModernise = "TO_MODERNISE",
  ToRenovate = "TO_RENOVATE",
  ToDemolish = "TO_DEMOLISH",
}

export enum propertyGScore {
  A = "A",
  B = "B",
  C = "C",
  D = "D",
}

export enum propertyPScore {
  A = "A",
  B = "B",
  C = "C",
  D = "D",
}

export enum propertyEpcLabel {
  APlus = "A+",
  A = "A",
  B = "B",
  C = "C",
  D = "D",
  E = "E",
  F = "F",
  G = "G",
}

export enum Orientations {
  Nort = "NORTH",
  NortEast = "NORTHEAST",
  East = "EAST",
  SouthEast = "SOUTHEAST",
  South = "SOUTH",
  SouthWest = "SOUTHWEST",
  West = "WEST",
  NortWest = "NORTHWEST",
}

export enum PropertyZones {
  Residential = "RESIDENTIAL",
  Country = "COUNTRY",
  Commercial = "COMMERCIAL",
  Center = "CENTER",
  PrimaryRoad = "PRIMARY_ROAD",
}

export enum RoomTypes {
  Cellar = "CELLAR",
  EntranceHall = "ENTRANCE_HALL",
  Toilet = "TOILET",
  Lavatory = "LAVATORY",
  LivingRoom = "LIVING_ROOM",
  Kitchen = "KITCHEN",
  Scullery = "SCULLERY",
  Storage = "STORAGE",
  NightHall = "NIGHT_HALL",
  Bedroom = "BEDROOM",
  Dressing = "DRESSING",
  Bathroom = "BATHROOM",
  Attic = "ATTIC",
  Office = "OFFICE",
  CommercialRoom = "COMMERCIAL_ROOM",
  Annex = "ANNEX",
  GardenShed = "GARDEN_SHED",
  Porch = "PORCH",
  Terrace = "TERRACE",
  Garage = "GARAGE",
  Other = "OTHER",
}

export enum RoomKitchenTypes {
  NotInstalled = "NOT_INSTALLED",
  PartiallyInstalled = "PARTIALLY_INSTALLED",
  Installed = "INSTALLED",
}

export enum RoomBathroomTypes {
  NotInstalled = "NOT_INSTALLED",
  Shower = "SHOWER",
  Bathtub = "BATHTUB",
  SittingBath = "SITTING_BATH",
  ShowerInBathtub = "SHOWER_IN_BATHTUB",
  ShowerInSittingBath = "SHOWER_IN_SITTING_BATH",
  ShowerInBath = "SHOWER_IN_BATH",
  AllComfort = "ALL_COMFORT",
  Luxurious = "LUXURIOUS",
}
