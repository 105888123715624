import Vue from "vue";
import Component from "vue-class-component";
import { Getter, Action } from "vuex-class";
import { cloneDeep, isNil } from "lodash";
import { omitDeepBy } from "lodash-omitdeep";

import { Mixins } from "vue-property-decorator";
import { HasPropertyOptions } from "@/mixins/properties/has-options";
import { PropertyGoal } from "@/enums/property";

export declare interface PropertyInfoBlock {
  id: string;
  icon: string;
  title: string;
  description: string;
  values: PropertyInfoBlockValue[];
}

export declare interface PropertyInfoBlockValue {
  /**
   * The required key that is also present on the payload or meta payload
   */
  key: string;
  group?: string;
  label?: string;
  allowed?: boolean;
  type?: "label" | "subtypes" | "number" | "yes-no" | "money" | "select" | "multiselect" | "textinput" | "textarea" | "wysiwyg" | "date" | "rooms" | "modality-notes";
  cols?: 1 | 2;
  hidden?: boolean;
  props?: {
    /**
     * The input name used in validations
     */
    name?: string;

    /**
     * Used on subtypes to filter the subtypes by type
     */
    type?: string | null;

    /**
     * Determine whether the input should be required or not
     */
    required?: boolean;

    /**
     * pass additional class names to the label
     */
    labelClass?: string;

    /**
     * whether the input should be disabled or not
     */
    disabled?: boolean;

    /**
     * The suffix of the input
     *
     * @example m | m² | %
     */
    suffix?: string;

    /**
     * The options of the input
     */
    options?: Array<{ label: string; value: string | number }>;

    /**
     * Wheter the input should be clearable or not
     * (Only for select and multiselect)
     */
    clearable?: boolean;

    /**
     * Whether the input can be reset to a null value
     * (Only for yes-no fields)
     */
    nullable?: boolean;

    /**
     * The height of the field
     * (Only for textarea)
     */
    height?: string;

    /**
     * The secondary label aligned to the right
     */
    subLabel?: string;
  };
  callback?: (value: any) => void;
}

@Component
export class HasPropertyConfig extends Mixins(HasPropertyOptions) {
  @Action("property/read") readProperty!: (payload: { activity_id: number }) => Promise<Property>;

  property: Property | null = null;

  activityId = Number(this.$route.params.id);

  configLoading = false;

  payload: PropertyUpdatePayload = {
    property_id: NaN,
    activity_id: NaN,
  };

  /**
   * @name Get property characteristics
   * @description This is an alias for the property.characteristics
   *
   * @returns {PropertyCharacteristics}
   */
  get propChar() {
    return this.property?.characteristics;
  }

  /**
   * @name Get the payload characteristics
   * @description This is an alias for the payload.characteristics
   *
   * @returns {PropertyCharacteristics}
   */
  get plChar() {
    return this.payload?.characteristics;
  }

  get building(): PropertyInfoBlock {
    let values: PropertyInfoBlockValue[] = [
      { group: "type", type: "label", key: "labels.type" },
      { group: "type", type: "select", key: "type", props: { required: true, options: this.propertyTypeOptions, disabled: true } },
      { group: "type", type: "subtypes", key: "subtype", props: { required: true, type: this.plChar?.type } },

      { group: "building", type: "label", key: "labels.building" },
      {
        group: "building",
        type: "select",
        key: "purpose",
        props: { clearable: true, options: this.propertyGoalOptions },
        callback: (value) => (value === PropertyGoal.Resell ? (this.plChar!.project_vat = null) : ""),
      },
      {
        group: "building",
        type: "select",
        key: "project_vat",
        hidden: this.plChar?.purpose !== PropertyGoal.NewConstruction,
        props: { clearable: true, options: this.propertyProjectVatOptions },
      },
      { group: "building", type: "select", key: "building.type", props: { required: true, options: this.propertyBuildingTypeOptions } },
      { group: "building", type: "select", key: "building.state", props: { required: true, options: this.propertyBuildingStateOptions } },
      { group: "building", type: "number", key: "building.construction_year" },
      { group: "building", type: "yes-no", key: "building.is_renovated", props: { nullable: true } },
      { group: "building", type: "number", key: "building.renovation_year", props: { disabled: !this.plChar?.building.is_renovated } },
      { group: "building", type: "yes-no", key: "building.plans_available", props: { nullable: true } },
    ];

    return {
      id: "building",
      icon: "home",
      title: "Bebouwing",
      description: "Informatie over de bebouwing",
      values: this.mapValuesForInfoBlock(values),
    };
  }

  get environment(): PropertyInfoBlock {
    let values: PropertyInfoBlockValue[] = [
      { group: "area", type: "label", key: "labels.area" },
      { group: "area", type: "number", key: "area.length", props: { suffix: "m" } },
      { group: "area", type: "number", key: "area.width", props: { suffix: "m" } },
      { group: "area", type: "number", key: "ground_area", label: "Oppervlakte", props: { suffix: "m²" } },
      { group: "area", type: "number", key: "area.facade_width", props: { suffix: "m" } },

      { group: "zone", type: "label", key: "labels.zone" },
      { group: "zone", type: "multiselect", key: "zone", props: { options: this.propertyZoneOptions } },
      { group: "zone", type: "money", key: "cadastral_income" },
      { group: "zone", type: "textinput", key: "cadastral_section" },
      { group: "zone", type: "textinput", key: "cadastral_number" },
      { group: "zone", type: "number", key: "cadastral_surface", props: { suffix: "m²" } },
      { group: "zone", type: "textinput", key: "cadastral_department" },
      { group: "zone", type: "yes-no", key: "show_address", props: { nullable: true } },

      { group: "flood_risk", type: "label", key: "labels.flood_risk" },
      { group: "flood_risk", type: "select", key: "area.flood_risk_o_peil", props: { clearable: true, options: this.propertyFloodRiskOPeilOptions } },
      { group: "flood_risk", type: "select", key: "area.flood_risk_2023", props: { clearable: true, options: this.propertyFloodRisk2023Options } },
      { group: "flood_risk", type: "select", key: "area.g_score", props: { clearable: true, options: this.propertyGScoreOptions } },
      { group: "flood_risk", type: "select", key: "area.p_score", props: { clearable: true, options: this.propertyPScoreOptions } },
      { group: "flood_risk", type: "yes-no", key: "area.signal_area", props: { nullable: true } },

      { group: "livable_area", type: "label", key: "labels.livable_area" },
      { group: "livable_area", type: "yes-no", key: "area.stores", callback: (value) => (value === false ? (this.plChar!.area.stores_distance = null) : ""), props: { nullable: true } },
      { group: "livable_area", type: "number", key: "area.stores_distance", hidden: !this.plChar?.area.stores, props: { suffix: "m", labelClass: "opacity-0" } },
      {
        group: "livable_area",
        type: "yes-no",
        key: "area.schools",
        callback: (value) => (value === false ? (this.plChar!.area.schools_distance = null) : ""),
        props: { nullable: true },
      },
      { group: "livable_area", type: "number", key: "area.schools_distance", hidden: !this.plChar?.area.schools, props: { suffix: "m", labelClass: "opacity-0" } },
      {
        group: "livable_area",
        type: "yes-no",
        key: "area.public_transport",
        callback: (value) => (value === false ? (this.plChar!.area.public_transport_distance = null) : ""),
        props: { nullable: true },
      },
      { group: "livable_area", type: "number", key: "area.public_transport_distance", hidden: !this.plChar?.area.public_transport, props: { suffix: "m", labelClass: "opacity-0" } },
      {
        group: "livable_area",
        type: "yes-no",
        key: "area.sportscenter",
        callback: (value) => (value === false ? (this.plChar!.area.sportscenter_distance = null) : ""),
        props: { nullable: true },
      },
      { group: "livable_area", type: "number", key: "area.sportscenter_distance", hidden: !this.plChar?.area.sportscenter, props: { suffix: "m", labelClass: "opacity-0" } },
      {
        group: "livable_area",
        type: "yes-no",
        key: "area.highways",
        callback: (value) => (value === false ? (this.plChar!.area.highways_distance = null) : ""),
        props: { nullable: true },
      },
      { group: "livable_area", type: "number", key: "area.highways_distance", hidden: !this.plChar?.area.highways, props: { suffix: "m", labelClass: "opacity-0" } },
      {
        group: "livable_area",
        type: "yes-no",
        key: "area.station",
        callback: (value) => (value === false ? (this.plChar!.area.station_distance = null) : ""),
        props: { nullable: true },
      },
      { group: "livable_area", type: "number", key: "area.station_distance", hidden: !this.plChar?.area.station, props: { suffix: "m", labelClass: "opacity-0" } },
    ];

    return {
      id: "environment",
      icon: "photograph",
      title: "Omgeving",
      description: "Informatie over het perceel en de omgeving.",
      values: this.mapValuesForInfoBlock(values),
    };
  }

  get features() {
    let values: PropertyInfoBlockValue[] = [
      // Bouw info
      { type: "label", key: "labels.features" },
      { type: "number", key: "area.building_length", props: { suffix: "m" } },
      { type: "number", key: "area.building_width", props: { suffix: "m" } },
      { type: "number", key: "area.parking_length", props: { suffix: "m" } },
      { type: "number", key: "area.parking_width", props: { suffix: "m" } },
      { type: "number", key: "area.parking_height", props: { suffix: "m" } },
      { type: "yes-no", key: "area.in_allotment", props: { nullable: true } },

      { type: "number", key: "ground_area", props: { required: true, suffix: "m²" } },
      { type: "number", key: "livable_area", hidden: this.plChar?.type === "PARKING", props: { required: true, suffix: "m²" } },
      { type: "number", key: "livable_area", label: "Oppervlakte", hidden: this.plChar?.type !== "PARKING", props: { required: true, suffix: "m²" } },
      { type: "number", key: "floor" },
      { type: "number", key: "floors" },
      { type: "number", key: "features.bedrooms", callback: () => this.callbackMaybeAddRooms() },
      { type: "number", key: "features.bathrooms", callback: () => this.callbackMaybeAddRooms() },
      {
        type: "yes-no",
        key: "features.furnished",
        props: { required: true, nullable: true },
        callback: (value) => (!value ? (this.plChar!.features.furnished_immovable_property_price = this.plChar!.features.furnished_immovable_property = null) : ""),
      },
      { type: "money", key: "features.furnished_immovable_property_price", hidden: !this.plChar?.features.furnished },
      { type: "textarea", key: "features.furnished_immovable_property", hidden: !this.plChar?.features.furnished },
      { type: "yes-no", key: "features.has_garage", callback: () => this.callbackMaybeChangeGarageCount(), props: { required: true, nullable: true } },
      { type: "number", key: "features.garage_count", hidden: !this.plChar?.features.has_garage },
      // Parking
      {
        type: "yes-no",
        key: "features.has_parking",
        callback: (value) => (!value ? (this.plChar!.features.has_outside_parking = this.plChar!.features.has_inside_parking = null) : ""),
        props: { required: true, nullable: true },
      },
      {
        type: "yes-no",
        key: "features.has_outside_parking",
        hidden: !this.plChar?.features.has_parking,
        callback: () => this.callbackMaybeChangeParkingCount(),
        props: { nullable: true },
      },
      { type: "number", key: "features.outside_parking_count", hidden: !this.plChar?.features.has_outside_parking },
      {
        type: "yes-no",
        key: "features.has_inside_parking",
        hidden: !this.plChar?.features.has_parking,
        callback: () => this.callbackMaybeChangeParkingCount(),
        props: { nullable: true },
      },
      { type: "number", key: "features.inside_parking_count", hidden: !this.plChar?.features.has_inside_parking },
      // Garden
      {
        type: "yes-no",
        key: "features.has_garden",
        callback: (value) => (!value ? (this.plChar!.features.garden_orientation = this.plChar!.features.garden_surface = null) : ""),
        props: { nullable: true },
      },
      { type: "select", key: "features.garden_orientation", hidden: !this.plChar?.features.has_garden, props: { clearable: true, options: this.propertyOrientationOptions } },
      { type: "number", key: "features.garden_surface", hidden: !this.plChar?.features.has_garden, props: { suffix: "m²" } },
      // Terrace
      {
        type: "yes-no",
        key: "features.has_terrace",
        props: { required: true, nullable: true },
        callback: (value) => (!value ? (this.plChar!.features.terrace_orientation = this.plChar!.features.terrace_surface = null) : ""),
      },
      { type: "select", key: "features.terrace_orientation", hidden: !this.plChar?.features.has_terrace, props: { clearable: true, options: this.propertyOrientationOptions } },
      { type: "number", key: "features.terrace_surface", hidden: !this.plChar?.features.has_terrace, props: { suffix: "m²" } },

      // Subkenmerken
      { group: "attachments", type: "number", key: "area.lateral_space", props: { suffix: "m" } },
      { group: "attachments", type: "number", key: "area.reversing_zone", props: { suffix: "m" } },
      // Water & elektriciteit
      { group: "attachments", type: "label", key: "labels.attachments" },
      { group: "attachments", type: "yes-no", key: "features.has_water", props: { nullable: true } },
      { group: "attachments", type: "yes-no", key: "features.has_gas", props: { nullable: true } },
      { group: "attachments", type: "yes-no", key: "features.charging_station", props: { nullable: true } },
      // Aansluitingen
      { group: "attachments", type: "yes-no", key: "features.has_plumbing", props: { nullable: true } },
      { group: "attachments", type: "yes-no", key: "features.has_electricity", props: { nullable: true } },
    ];

    return {
      id: "features",
      icon: "collection",
      title: "Hoofdkenmerken",
      description: "De belangrijkste kenmerken van het eigendom.",
      values: this.mapValuesForInfoBlock(values),
    };
  }

  get rooms() {
    let values: PropertyInfoBlockValue[] = [{ type: "rooms", key: "rooms", cols: 1 }];

    return {
      id: "rooms",
      icon: "collection",
      title: "Ruimtes",
      description: "Alle ruimtes in het eigendom.",
      values: this.mapValuesForInfoBlock(values),
    };
  }

  get installation() {
    let values: PropertyInfoBlockValue[] = [
      { group: "heating", type: "label", key: "labels.heating" },
      { group: "heating", type: "yes-no", key: "installation.heating.has_centralized_heating", props: { nullable: true } },
      {
        group: "heating",
        type: "multiselect",
        key: "installation.heating.centralized_heating",
        props: { options: this.propertyCentralizedHeatingOptions, clearable: true, disabled: !this.plChar?.installation?.heating?.has_centralized_heating },
      },
      {
        group: "heating",
        type: "multiselect",
        key: "installation.heating.centralized_heating_fuel",
        props: { options: this.propertyCentralizedHeatingFuelOptions, clearable: true, disabled: !this.plChar?.installation?.heating?.has_centralized_heating },
      },
      {
        group: "heating",
        type: "select",
        key: "installation.heating.heating_type",
        props: { options: this.propertyHeatingTypeOptions, clearable: true, disabled: !this.plChar?.installation?.heating?.has_centralized_heating },
      },
      { group: "heating", type: "yes-no", key: "installation.heating.has_decentralized_heating", props: { nullable: true } },
      {
        group: "heating",
        type: "multiselect",
        key: "installation.heating.decentralized_heating",
        props: { options: this.propertyDeCentralizedHeatingOptions, clearable: true, disabled: !this.plChar?.installation?.heating?.has_decentralized_heating },
      },
      { group: "heating", type: "yes-no", key: "installation.heating.has_water_heating", props: { nullable: true } },
      {
        group: "heating",
        type: "multiselect",
        key: "installation.heating.water_heating",
        props: { options: this.propertyWaterHeatingOptions, clearable: true, disabled: !this.plChar?.installation?.heating?.has_water_heating },
      },

      { group: "specialized", type: "label", key: "labels.specialized" },
      { group: "specialized", type: "yes-no", key: "equipment.specialized.solar_water_heater", props: { nullable: true } },
      { group: "specialized", type: "yes-no", key: "equipment.specialized.water_softener", props: { nullable: true } },
      { group: "specialized", type: "yes-no", key: "equipment.specialized.has_solar_panels", props: { nullable: true } },
      { group: "specialized", type: "number", key: "equipment.specialized.solar_panels", props: { disabled: !this.plChar?.equipment?.specialized?.has_solar_panels } },
      {
        group: "specialized",
        type: "yes-no",
        key: "equipment.specialized.solar_panel_certificate",
        props: { disabled: !this.plChar?.equipment?.specialized?.has_solar_panels, nullable: true },
      },
      {
        group: "specialized",
        type: "number",
        key: "equipment.specialized.solar_panel_certificates_per_year",
        props: { disabled: !this.plChar?.equipment?.specialized?.has_solar_panels },
      },
      {
        group: "specialized",
        type: "money",
        key: "equipment.specialized.solar_panel_amount_per_certificate",
        props: { disabled: !this.plChar?.equipment?.specialized?.has_solar_panels },
      },

      { group: "rainwater_collection", type: "label", key: "labels.rainwater_collection" },
      { group: "rainwater_collection", type: "yes-no", key: "equipment.specialized.rainwater_collection.toilet", props: { nullable: true } },
      { group: "rainwater_collection", type: "yes-no", key: "equipment.specialized.rainwater_collection.outdoor_faucet", props: { nullable: true } },
      { group: "rainwater_collection", type: "yes-no", key: "equipment.specialized.rainwater_collection.indoor_faucet", props: { nullable: true } },
      { group: "rainwater_collection", type: "yes-no", key: "equipment.specialized.rainwater_collection.washing_machine", props: { nullable: true } },
      { group: "rainwater_collection", type: "number", key: "equipment.specialized.rainwater_collection.water_wells" },
      // TODO: water_well_volume_liters (array of numbers)

      { group: "septic_tank", type: "label", key: "labels.septic_tank" },
      { group: "septic_tank", type: "yes-no", key: "equipment.specialized.septic_tank_available", props: { nullable: true } },
      {
        group: "septic_tank",
        type: "number",
        key: "equipment.specialized.septic_tank_volume",
        props: { suffix: "liter", disabled: !this.plChar?.equipment?.specialized?.septic_tank_available },
      },

      { group: "electricity", type: "label", key: "labels.electricity" },
      { group: "electricity", type: "yes-no", key: "installation.electrical.has_meter", props: { nullable: true } },
      {
        group: "electricity",
        type: "select",
        key: "installation.electrical.meter",
        props: { options: this.propertyElectricalOptions, clearable: true, disabled: !this.plChar?.installation?.electrical?.has_meter },
      },

      { group: "ventilation_system", type: "label", key: "labels.ventilation_system" },
      { group: "ventilation_system", type: "yes-no", key: "installation.ventilation.has_ventilation_system", props: { nullable: true } },
      {
        group: "ventilation_system",
        type: "select",
        key: "installation.ventilation.system_grade",
        props: { options: this.propertyVentilationOptions, clearable: true, disabled: !this.plChar?.installation?.ventilation?.has_ventilation_system },
      },
    ];

    return {
      id: "installation",
      icon: "lightning-bolt",
      title: "Installaties",
      description: "De installaties die in het eigendom aanwezig zijn.",
      values: this.mapValuesForInfoBlock(values),
    };
  }

  get construction() {
    let values: PropertyInfoBlockValue[] = [
      /**
       * -----------------
       * GEVEL
       * -----------------
       *
       */
      { group: "facade", type: "label", key: "labels.facade" },
      {
        group: "facade",
        type: "multiselect",
        key: "construction.facade.material",
        props: { options: this.propertyFacadeMaterialOptions, clearable: true },
        callback: (value) => (!value.includes("OTHER") ? (this.plChar!.construction.facade.material_other = null) : ""),
      },
      { group: "facade", type: "textinput", key: "construction.facade.material_other", hidden: !this.plChar?.construction.facade.material.includes("OTHER") },
      {
        group: "facade",
        type: "yes-no",
        key: "construction.facade.insulation_available",
        callback: (value) => (!value ? (this.plChar!.construction.facade.insulation_type = this.plChar!.construction.facade.insulation_thickness = null) : ""),
        props: { nullable: true },
      },
      { group: "facade", type: "textinput", key: "construction.facade.insulation_type", hidden: !this.plChar?.construction.facade.insulation_available },
      { group: "facade", type: "number", key: "construction.facade.insulation_thickness", hidden: !this.plChar?.construction.facade.insulation_available, props: { suffix: "cm" } },

      /**
       * -----------------
       * DAK
       * -----------------
       *
       */
      { group: "roof", type: "label", key: "labels.roof" },
      {
        group: "roof",
        type: "multiselect",
        key: "construction.roof.material",
        props: { options: this.propertyRoomMaterialOptions, clearable: true },
        callback: (value) => (!value.includes("OTHER") ? (this.plChar!.construction.roof.material_other = null) : ""),
      },
      { group: "roof", type: "textinput", key: "construction.roof.material_other", hidden: !this.plChar?.construction.roof.material.includes("OTHER") },
      {
        group: "roof",
        type: "yes-no",
        key: "construction.roof.insulation_available",
        callback: (value) => (!value ? (this.plChar!.construction.roof.insulation_type = this.plChar!.construction.roof.insulation_thickness = null) : ""),
        props: { nullable: true },
      },
      { group: "roof", type: "textinput", key: "construction.roof.insulation_type", hidden: !this.plChar?.construction.roof.insulation_available },
      { group: "roof", type: "number", key: "construction.roof.insulation_thickness", hidden: !this.plChar?.construction.roof.insulation_available, props: { suffix: "cm" } },

      /**
       * -----------------
       * BUITENSCHRIJNWERK
       * -----------------
       *
       */
      { group: "exterior_joinery", type: "label", key: "labels.exterior_joinery" },
      { group: "exterior_joinery", type: "multiselect", key: "construction.exterior_joinery.windows", props: { options: this.propertyExteriorJoineryWindowOptions, clearable: true } },
      {
        group: "exterior_joinery",
        type: "multiselect",
        key: "construction.exterior_joinery.material",
        props: { options: this.propertyExteriorJoineryMaterialOptions, clearable: true },
      },
      { group: "exterior_joinery", type: "textinput", key: "construction.exterior_joinery.notes" },

      /**
       * -----------------
       * ROLLUIKEN
       * -----------------
       *
       */
      { group: "roller_shutters", type: "label", key: "labels.roller_shutters" },
      { group: "roller_shutters", type: "yes-no", key: "installation.roller_shutters.has_roller_shutters", props: { nullable: true } },
      { group: "roller_shutters", type: "yes-no", key: "installation.roller_shutters.automatic", props: { nullable: true } },
      { group: "roller_shutters", type: "yes-no", key: "installation.roller_shutters.full_installation", props: { nullable: true } },
      {
        group: "roller_shutters",
        type: "multiselect",
        key: "installation.roller_shutters.material",
        props: { options: this.propertyRollerShuttersMaterialOptions, clearable: true },
        callback: (value) => (!value.includes("OTHER") ? (this.plChar!.installation!.roller_shutters.material_other = null) : ""),
      },
      {
        group: "roller_shutters",
        type: "textinput",
        key: "installation.roller_shutters.material_other",
        hidden: !this.plChar?.installation?.roller_shutters.material.includes("OTHER"),
      },

      /**
       * -----------------
       * GARAGEPOORT
       * -----------------
       *
       */
      { group: "garage_door", type: "label", key: "labels.garage_door" },
      { group: "garage_door", type: "yes-no", key: "installation.garage_door.has_garage_door", props: { nullable: true } },
      {
        group: "garage_door",
        type: "yes-no",
        key: "installation.garage_door.automatic",
        props: {
          disabled: !this.plChar?.installation?.garage_door?.has_garage_door,
          nullable: true,
        },
      },
      {
        group: "garage_door",
        type: "select",
        key: "installation.garage_door.material",
        props: { options: this.propertyGarageDoorMaterialOptions, clearable: true, disabled: !this.plChar?.installation?.garage_door?.has_garage_door },
        callback: (value) => (!value.includes("OTHER") ? (this.plChar!.installation!.garage_door.material_other = null) : ""),
      },
      {
        group: "garage_door",
        type: "textinput",
        key: "installation.garage_door.material_other",
        hidden: !this.plChar?.installation?.garage_door?.material?.includes("OTHER"),
        props: { disabled: !this.plChar?.installation?.garage_door?.has_garage_door },
      },

      /**
       * -----------------
       * BINNENAFWERKING
       * -----------------
       *
       */
      { group: "wall_finishing", type: "label", key: "labels.wall_finishing" },
      {
        group: "wall_finishing",
        type: "multiselect",
        key: "construction.wall_finishing.material",
        props: { options: this.propertyWallFinishingMaterialOptions, clearable: true },
        callback: (value) => (!value.includes("OTHER") ? (this.plChar!.construction.wall_finishing.material_other = null) : ""),
      },
      { group: "wall_finishing", type: "textinput", key: "construction.wall_finishing.material_other", hidden: !this.plChar?.construction?.wall_finishing?.material?.includes("OTHER") },
      {
        group: "wall_finishing",
        type: "multiselect",
        key: "construction.ceiling_finishing.material",
        props: { options: this.propertyCeilingFinishingMaterialOptions, clearable: true },
      },
      {
        group: "wall_finishing",
        type: "textinput",
        key: "construction.ceiling_finishing.material_other",
        hidden: !this.plChar?.construction?.ceiling_finishing?.material?.includes("OTHER"),
      },
      { group: "wall_finishing", type: "multiselect", key: "construction.floor_finishing.material", props: { options: this.propertyFloorFinishingMaterialOptions, clearable: true } },
      {
        group: "wall_finishing",
        type: "textinput",
        key: "construction.floor_finishing.material_other",
        hidden: !this.plChar?.construction?.floor_finishing?.material?.includes("OTHER"),
      },
      { group: "wall_finishing", type: "multiselect", key: "construction.mezzanine.material", props: { options: this.propertyMezzanineMaterialOptions, clearable: true } },
      { group: "wall_finishing", type: "textinput", key: "construction.mezzanine.material_other", hidden: !this.plChar?.construction?.mezzanine?.material?.includes("OTHER") },

      /**
       * -----------------
       * ASBEST
       * -----------------
       *
       */
      { group: "asbestos_materials", type: "label", key: "labels.asbestos_materials" },
      { group: "asbestos_materials", type: "yes-no", key: "construction.asbestos_materials", props: { nullable: true } },
    ];

    return {
      id: "construction",
      icon: "library",
      title: "Constructie",
      description: "Informatie over de constructie van het eigendom.",
      values: this.mapValuesForInfoBlock(values),
    };
  }

  get equipment() {
    let values: PropertyInfoBlockValue[] = [
      /**
       * -----------------
       * KEUKENAPPARATUUR
       * -----------------
       *
       */
      { group: "kitchen", type: "label", key: "labels.kitchen" },
      { group: "kitchen", type: "yes-no", key: "equipment.kitchen.dishwasher", props: { nullable: true } },
      { group: "kitchen", type: "yes-no", key: "equipment.kitchen.air_extractor", props: { nullable: true } },
      { group: "kitchen", type: "yes-no", key: "equipment.kitchen.oven", props: { nullable: true } },
      { group: "kitchen", type: "yes-no", key: "equipment.kitchen.combi_oven", props: { nullable: true } },
      { group: "kitchen", type: "yes-no", key: "equipment.kitchen.microwave_oven", props: { nullable: true } },
      { group: "kitchen", type: "yes-no", key: "equipment.kitchen.steam_oven", props: { nullable: true } },
      { group: "kitchen", type: "yes-no", key: "equipment.kitchen.coffee_machine", props: { nullable: true } },
      { group: "kitchen", type: "yes-no", key: "equipment.kitchen.fridge", props: { nullable: true } },
      {
        group: "kitchen",
        type: "yes-no",
        key: "equipment.kitchen.stove",
        callback: (value) => (!value ? (this.plChar!.equipment.kitchen.stove_type = null) : ""),
        props: { nullable: true },
      },
      {
        group: "kitchen",
        type: "select",
        key: "equipment.kitchen.stove_type",
        hidden: !this.plChar?.equipment.kitchen.stove,
        props: { clearable: true, options: this.propertyStoveTypeOptions },
      },
      { group: "kitchen", type: "select", key: "equipment.kitchen.sink_type", props: { clearable: true, options: this.propertySinkTypeOptions } },

      /**
       * -----------------
       * SANITAIR
       * -----------------
       *
       */
      { group: "bathroom", type: "label", key: "labels.bathroom" },
      { group: "bathroom", type: "number", key: "equipment.bathroom.baths" },
      { group: "bathroom", type: "number", key: "equipment.bathroom.showers" },
      { group: "bathroom", type: "number", key: "equipment.bathroom.sinks" },
      { group: "bathroom", type: "number", key: "equipment.bathroom.double_sinks" },
      { group: "bathroom", type: "number", key: "equipment.bathroom.guest_toilets" },

      /**
       * -----------------
       * BEVEILIGING
       * -----------------
       *
       */
      { group: "security", type: "label", key: "labels.security" },
      { group: "security", type: "yes-no", key: "equipment.security.alarm_system", props: { nullable: true } },
      { group: "security", type: "yes-no", key: "equipment.security.intercom", props: { nullable: true } },
      { group: "security", type: "yes-no", key: "equipment.security.videophone", props: { nullable: true } },
      { group: "security", type: "yes-no", key: "equipment.security.camera_surveillance", props: { nullable: true } },
      { group: "security", type: "yes-no", key: "equipment.security.fire_detector", props: { nullable: true } },
      { group: "security", type: "yes-no", key: "equipment.security.gated_access", props: { nullable: true } },

      /**
       * -----------------
       * WELNESS
       * -----------------
       *
       */
      { group: "wellness", type: "label", key: "labels.wellness" },
      { group: "wellness", type: "yes-no", key: "wellness.pool", props: { nullable: true } },
      { group: "wellness", type: "yes-no", key: "wellness.sauna", props: { nullable: true } },
      { group: "wellness", type: "yes-no", key: "wellness.jacuzzi", props: { nullable: true } },
      { group: "wellness", type: "yes-no", key: "wellness.steam_bath", props: { nullable: true } },

      /**
       * -----------------
       * ANDERE
       * -----------------
       *
       */
      { group: "other", type: "label", key: "labels.other", props: { nullable: true } },
      { group: "other", type: "yes-no", key: "other.elevator", props: { nullable: true } },
      { group: "other", type: "yes-no", key: "other.home_automation", props: { nullable: true } },
    ];

    return {
      id: "equipment",
      icon: "video-camera",
      title: "Apparatuur",
      description: "Informatie over de apparatuur in het eigendom.",
      values: this.mapValuesForInfoBlock(values),
    };
  }

  get connections() {
    let values: PropertyInfoBlockValue[] = [
      /**
       * -----------------
       * NETWERKCONNECTIE
       * -----------------
       *
       */
      { group: "network_connection", type: "label", key: "labels.network_connection" },
      {
        group: "network_connection",
        type: "yes-no",
        key: "connections.network_connection_available",
        callback: (value) => (!value ? (this.plChar!.connections.network_provider = null) : ""),
        props: { nullable: true },
      },
      { group: "network_connection", type: "textinput", key: "connections.network_provider", hidden: !this.plChar?.connections.network_connection_available },

      /**
       * -----------------
       * ANDERE
       * -----------------
       *
       */
      { group: "other", type: "label", key: "labels.other" },
      { group: "other", type: "yes-no", key: "connections.cable_television", props: { nullable: true } },
    ];

    return {
      id: "connections",
      icon: "wifi",
      title: "Connecties",
      description: "Informatie over de connecties en aansluitingen.",
      values: this.mapValuesForInfoBlock(values),
    };
  }

  get general() {
    let values: PropertyInfoBlockValue[] = [
      /**
       * -----------------
       * PRIJS
       * -----------------
       *
       */
      { group: "price", type: "label", key: "labels.price" },
      { group: "price", type: "money", key: "sale.offer_from" },
      { group: "price", type: "money", key: "sale.minimum_offer" },
      { group: "price", type: "money", key: "sale.min_rent_price" },

      { group: "estimation", type: "label", key: "labels.estimation" },
      {
        group: "estimation",
        type: "select",
        key: "_meta.sale.price_or_range",
        props: { options: this.propertyPriceOrRageOptions },
        callback: (value) => {
          if (value === "FIXED") {
            this.plChar!.sale.price_range_min = this.plChar!.sale.price_range_max = null;
          } else if (value === "RANGE") {
            this.plChar!.sale.price_estimation = null;
          }
        },
      },
      { group: "estimation", type: "money", key: "sale.price_estimation", hidden: this.plChar?._meta!.sale!.price_or_range !== "FIXED" },
      { group: "estimation", type: "money", key: "sale.price_range_min", hidden: this.plChar?._meta!.sale!.price_or_range !== "RANGE" },
      { group: "estimation", type: "money", key: "sale.price_range_max", hidden: this.plChar?._meta!.sale!.price_or_range !== "RANGE" },
      { group: "estimation", type: "money", key: "sale.construction_cost" },
      { group: "estimation", type: "money", key: "sale.ground_value" },

      /**
       * -----------------
       * BTW EN REGISTRATIERECHTEN
       * -----------------
       *
       */
      { group: "vat_and_registation", type: "label", key: "labels.vat_and_registation" },
      { group: "vat_and_registation", type: "number", key: "sale.registration_rights_percent", props: { suffix: "%" } },
      {
        group: "vat_and_registation",
        type: "yes-no",
        key: "sale.vat_system",
        callback: (value) => {
          !value ? (this.plChar!.sale.vat_percent = this.plChar!.sale.vat_ground_value = null) : "";
        },
        props: { nullable: true },
      },
      {
        group: "vat_and_registation",
        type: "select",
        key: "sale.vat_percent",
        hidden: !this.plChar?.sale.vat_system,
        props: { clearable: true, options: this.propertyProjectVatOptions },
      },
      { group: "vat_and_registation", type: "money", key: "sale.vat_ground_value", hidden: !this.plChar?.sale.vat_system },

      /**
       * -----------------
       * SYNDICUS
       * -----------------
       *
       */
      { group: "syndic", type: "label", key: "labels.syndic" },
      { group: "syndic", type: "money", key: "building.syndicus_monthly_rate" },
      { group: "syndic", type: "textinput", key: "building.syndicus_information" },
    ];

    return {
      id: "general",
      icon: "currency-euro",
      title: "Prijs",
      description: "Informatie over de prijs van het eigendom.",
      values: this.mapValuesForInfoBlock(values),
    };
  }

  get modality() {
    let values: PropertyInfoBlockValue[] = [
      /**
       * -----------------
       * MODALITEITEN
       * -----------------
       *
       */
      { group: "modalities", type: "label", key: "labels.modalities", props: { subLabel: "Duid niets aan als je de informatie nog niet weet." } },
      { group: "modalities", type: "yes-no", key: "sales_promise.electricity_inspection_conform", props: { nullable: true } },
      { group: "modalities", type: "yes-no", key: "sales_promise.fuel_oil_tank_compliant", props: { nullable: true } },
      { group: "modalities", type: "yes-no", key: "sales_promise.asbestos_present", props: { nullable: true } },
      { group: "modalities", type: "yes-no", key: "sales_promise.struck_by_red_line", props: { nullable: true } },

      { group: "other_modalities", type: "label", key: "labels.other_modalities" },
      { group: "other_modalities", type: "modality-notes", key: "sales_promise.notes" },

      /**
       * -----------------
       * EPC
       * -----------------
       *
       */
      { group: "epc", type: "label", key: "labels.epc" },
      { group: "epc", type: "select", key: "sales_promise.epc_label", props: { clearable: true, options: this.propertyEpcLabelOptions } },
      { group: "epc", type: "number", key: "sales_promise.epc_score", props: { suffix: "kWh / (m2jaar)" } },
      { group: "epc", type: "textinput", key: "sales_promise.reference" },
      { group: "epc", type: "number", key: "sales_promise.e_peil" },

      /**
       * -----------------
       * EK
       * -----------------
       *
       */
      { group: "ek", type: "label", key: "labels.ek" },
      { group: "ek", type: "select", key: "sales_promise.electricity_certificate_available", props: { clearable: true, options: this.propertyYesNoConformOption } },
      { group: "ek", type: "date", key: "sales_promise.electricity_certificate_date" },

      /**
       * -----------------
       * ASBEST
       * -----------------
       *
       */
      { group: "asbestos", type: "label", key: "labels.asbestos" },
      { group: "asbestos", type: "yes-no", key: "sales_promise.asbestos_certificate_available", props: { nullable: true } },
      { group: "asbestos", type: "date", key: "sales_promise.asbestos_certificate_date" },

      /**
       * -----------------
       * STOOKOLIETANK
       * -----------------
       *
       */
      { group: "fuel_tank", type: "label", key: "labels.fuel_tank" },
      { group: "fuel_tank", type: "yes-no", key: "sales_promise.oiltank_certificate_available", props: { nullable: true } },

      /**
       * -----------------
       * BODEMATTEST
       * -----------------
       *
       */
      { group: "soil_certificate", type: "label", key: "labels.soil_certificate" },
      { group: "soil_certificate", type: "yes-no", key: "sales_promise.soil_certificate_available", props: { nullable: true } },

      /**
       * -----------------
       * BEWONING
       * -----------------
       *
       */
      { group: "livability", type: "label", key: "labels.livability" },
      { group: "livability", type: "select", key: "sales_promise.availability", props: { clearable: true, options: this.propertySalesPromiseAvailabilityOptions } },
      { group: "livability", type: "yes-no", key: "sales_promise.has_resident_allowance", props: { disabled: !this.plChar?.sales_promise.availability, nullable: true } },
      {
        group: "livability",
        type: "number",
        key: "sales_promise.habitable_after_deed_period",
        props: { suffix: "maanden", disabled: !this.plChar?.sales_promise.has_resident_allowance },
      },
      { group: "livability", type: "money", key: "sales_promise.resident_allowance", props: { disabled: !this.plChar?.sales_promise.has_resident_allowance } },
      {
        group: "livability",
        type: "select",
        key: "sales_promise.resident_allowance_period",
        props: { disabled: !this.plChar?.sales_promise.has_resident_allowance, clearable: true, options: this.propertyResidentAllowanceOptions },
      },

      /**
       * -----------------
       * JURIDISCH
       * -----------------
       *
       */
      { group: "legal", type: "label", key: "labels.legal" },
      { group: "legal", type: "yes-no", key: "sales_promise.servitude", props: { nullable: true } },
      { group: "legal", type: "yes-no", key: "sales_promise.building_permit", props: { nullable: true } },
      { group: "legal", type: "yes-no", key: "sales_promise.subdivision_permit", props: { nullable: true } },
      { group: "legal", type: "yes-no", key: "sales_promise.pre_emption_right", props: { nullable: true } },
      { group: "legal", type: "select", key: "sales_promise.terrain_destination", props: { clearable: true, options: this.propertyTerrainDestinationOptions } },
      { group: "legal", type: "yes-no", key: "sales_promise.summons", props: { nullable: true } },
      { group: "legal", type: "select", key: "sales_promise.summons_type", props: { clearable: true, options: this.propertyLegalSummonsOptions } },
      { group: "legal", type: "yes-no", key: "sales_promise.protected_heritage", props: { nullable: true } },
      { group: "legal", type: "yes-no", key: "sales_promise.heritage", props: { nullable: true } },
    ];

    return {
      id: "modality",
      icon: "paper-clip",
      title: "Aankoopbelofte",
      description: "Informatie over de keuringen en vergoedingen.",
      values: this.mapValuesForInfoBlock(values),
    };
  }

  get notes() {
    let values: PropertyInfoBlockValue[] = [
      { group: "notes", type: "label", key: "labels.notes" },
      { group: "notes", type: "textarea", cols: 1, key: "notes", props: { height: "400px" } },

      { group: "description", type: "label", key: "labels.description" },
      { group: "description", type: "wysiwyg", cols: 1, key: "_meta.description", props: { height: "400px" } },
    ];

    return {
      id: "notes",
      icon: "pencil-alt",
      title: "Beschrijving",
      cols: 1,
      description: "Omschrijf hier het eigendom of voeg notities over het eigendom toe.",
      values: this.mapValuesForInfoBlock(values),
    };
  }

  get propertyTabs() {
    return [
      this.building,
      this.environment,
      this.features,
      this.rooms,
      this.installation,
      this.construction,
      this.equipment,
      this.connections,
      this.general,
      this.modality,
      this.notes,
    ].filter(Boolean) as PropertyInfoBlock[];
  }

  async mounted() {
    this.configLoading = true;

    this.property = await this.readProperty({ activity_id: this.activityId });

    await this.setInitialPayload();

    this.configLoading = false;

    this.afterMounted();
  }

  /**
   * @name After mounted hook
   * @description This is a hook that can be used in the child component
   *
   */
  afterMounted() {}

  /**
   * @name Set the Initial payload
   * @description Set the initial payload of the property including the meta characteristics
   * - Clear null values
   * - Add meta characteristics
   * - Remove unwanted reactivity
   *
   * @returns {void}
   */
  async setInitialPayload() {
    return new Promise((resolve) => {
      if (!this.property || !this.propChar) {
        return resolve(false);
      }

      // This is temporary data, when the payload is submitted
      // This data will be removed from the payload.
      let metaCharacteristics: PropertyMetaCharacteristics = {
        _meta: {
          description: this.property.description,
          sale: {
            price_or_range: this.propChar.sale.price_range_min || this.propChar.sale.price_range_max ? "RANGE" : "FIXED",
          },
        },
      };

      this.payload = {
        property_id: this.property.id,
        activity_id: this.property.activity_id,
        address: this.property.address,
        description: this.property.description,
        characteristics: {
          ...(cloneDeep(this.propChar) as PropertyCharacteristics),
          ...metaCharacteristics,
        },
      };

      return resolve(true);
    });
  }

  /**
   * Map values for info block
   *
   * @description
   * Map the values to the info block
   * - Will set a translated label based on the key, if no label is provided
   * - Will determine wheter the property is allowed based on the type
   * - Will set a default name if none is provided
   *
   * @param {PropertyInfoBlockValue[]} values
   *
   * @returns {PropertyInfoBlockValue[]}
   */
  mapValuesForInfoBlock(values: PropertyInfoBlockValue[]): PropertyInfoBlockValue[] {
    return values.map((v: PropertyInfoBlockValue) => ({
      ...v,
      label: v?.label ? v.label : (this.$t(`property.form.titles.${v.key}`) as string),
      allowed: this.isAllowed(v.key),
      props: { ...(v.props ?? {}), name: v.props?.name ? v.props.name : v.key.replace(".", "_") },
    }));
  }

  /**
   * Is allowed?
   *
   * @description Check if the given key is hidden or not based on the property type.
   *
   * @param {string} key
   */
  isAllowed(key: string) {
    if (!this.payload || !this.plChar || !this.plChar.type) {
      return false;
    }

    let type = this.plChar.type;

    switch (key) {
      /**
       * Always show for all property types
       */
      case "labels.type":
      case "labels.building":
      case "labels.features":
      case "labels.zone":
      case "labels.flood_risk":
      case "labels.livable_area":
      case "labels.price":
      case "labels.estimation":
      case "labels.notes":
      case "labels.description":
      case "labels.attachments":
      case "type":
      case "subtype":
      case "notes":
      case "description":
      case "show_address":
      case "sale.offer_from":
      case "sale.minimum_offer":
      case "sale.price_estimation":
      case "sale.price_range_min":
      case "sale.price_range_max":
      case "_meta.sale.price_or_range":
      case "area.flood_risk_o_peil":
      case "area.flood_risk_2023":
      case "area.signal_area":
      case "area.stores":
      case "area.stores_distance":
      case "area.schools":
      case "area.schools_distance":
      case "area.public_transport":
      case "area.public_transport_distance":
      case "area.sportscenter":
      case "area.sportscenter_distance":
      case "area.highways":
      case "area.highways_distance":
      case "area.station":
      case "area.station_distance":
      case "_meta.description":
      case "features.has_plumbing":
      case "features.has_water":
      case "features.has_gas":
      case "features.has_electricity":
      case "sale.min_rent_price":
        return true;

      /**
       *  Show when the type is not LAND
       *  (Huis | Appartement | Commercieel | Parking)
       */
      case "purpose":
      case "project_vat":
      case "labels.garage_door":
      case "features.has_garage":
      case "features.garage_count":
      case "features.has_outside_parking":
      case "features.has_inside_parking":
      case "features.outside_parking_count":
      case "features.inside_parking_count":
      case "features.has_parking":
      case "features.charging_station":
      case "installation.garage_door.has_garage_door":
      case "installation.garage_door.automatic":
      case "installation.garage_door.material":
      case "installation.garage_door.material_other":
      case "sale.construction_cost":
      case "sale.ground_value":
      case "sale.vat_ground_value":
      case "livable_area":
        return ["HOUSE", "APPARTMENT", "COMMERCIAL", "PARKING"].includes(type);

      /**
       * Show when the type is HOUSE, APPARTMENT or COMMERCIAL
       * (Huis | Appartement | Commercieel | Bouwgrond)
       */
      case "zone":
      case "labels.area":
      case "area.length":
      case "area.width":
      case "area.g_score":
      case "building.type":
      case "area.p_score":
      case "building.plans_available":
      case "cadastral_income":
      case "cadastral_section":
      case "cadastral_number":
      case "cadastral_surface":
      case "cadastral_department":
      case "ground_area":
        return ["HOUSE", "APPARTMENT", "COMMERCIAL", "LAND"].includes(type);

      /**
       * Show when the type is HOUSE, APPARTMENT or COMMERCIAL
       * (Huis | Appartement | Commercieel)
       */
      case "labels.heating":
      case "labels.specialized":
      case "labels.electricity":
      case "labels.ventilation_system":
      case "labels.modalities":
      case "labels.epc":
      case "labels.livability":
      case "labels.facade":
      case "labels.roof":
      case "labels.exterior_joinery":
      case "labels.roller_shutters":
      case "labels.wall_finishing":
      case "labels.asbestos_materials":
      case "labels.kitchen":
      case "labels.bathroom":
      case "labels.security":
      case "labels.wellness":
      case "labels.other":
      case "labels.network_connection":
      case "labels.ek":
      case "labels.asbestos":
      case "labels.fuel_tank":
      case "labels.soil_certificate":
      case "labels.rainwater_collection":
      case "labels.septic_tank":
      case "labels.other_modalities":
      case "labels.legal":
      case "labels.vat_and_registation":
      case "area.length":
      case "area.width":
      case "building.construction_year":
      case "building.is_renovated":
      case "building.renovation_year":
      case "area.facade_width":
      case "livable_area":
      case "features.bedrooms":
      case "features.bathrooms":
      case "features.furnished":
      case "features.furnished_immovable_property_price":
      case "features.has_garden":
      case "features.garden_surface":
      case "features.has_terrace":
      case "features.terrace_surface":
      case "features.furnished_immovable_property":
      case "features.garden_orientation":
      case "features.terrace_orientation":
      case "installation.heating.has_centralized_heating":
      case "installation.heating.has_decentralized_heating":
      case "installation.heating.has_water_heating":
      case "installation.heating.centralized_heating":
      case "installation.heating.centralized_heating_fuel":
      case "installation.heating.decentralized_heating":
      case "installation.heating.water_heating":
      case "installation.heating.heating_type":
      case "equipment.specialized.has_solar_panels":
      case "equipment.specialized.solar_panels":
      case "equipment.specialized.solar_panel_certificate":
      case "equipment.specialized.solar_water_heater":
      case "equipment.specialized.solar_panel_certificates_per_year":
      case "equipment.specialized.solar_panel_amount_per_certificate":
      case "equipment.specialized.water_softener":
      case "equipment.specialized.rainwater_collection.water_wells":
      case "equipment.specialized.rainwater_collection.toilet":
      case "equipment.specialized.rainwater_collection.outdoor_faucet":
      case "equipment.specialized.rainwater_collection.indoor_faucet":
      case "equipment.specialized.rainwater_collection.washing_machine":
      case "equipment.specialized.septic_tank_available":
      case "equipment.specialized.septic_tank_volume":
      case "installation.electrical.has_meter":
      case "installation.electrical.meter":
      case "installation.ventilation.has_ventilation_system":
      case "installation.ventilation.system_grade":
      case "construction.facade.material":
      case "construction.facade.material_other":
      case "construction.facade.insulation_available":
      case "construction.facade.insulation_type":
      case "construction.facade.insulation_thickness":
      case "construction.exterior_joinery.material":
      case "construction.exterior_joinery.windows":
      case "construction.exterior_joinery.notes":
      case "installation.roller_shutters.has_garage_door":
      case "installation.roller_shutters.automatic":
      case "installation.roller_shutters.full_instalation":
      case "installation.roller_shutters.material":
      case "installation.roller_shutters.material_oher":
      case "construction.wall_finishing.material":
      case "construction.wall_finishing.material_oter":
      case "construction.ceiling_finishing.material":
      case "construction.ceiling_finishing.materialother":
      case "construction.floor_finishing.material":
      case "construction.floor_finishing.material_oher":
      case "construction.mezzanine.material":
      case "construction.mezzanine.material_other":
      case "construction.asbestos_materials":
      case "sales_promise.habitable_after_deed_period":
      case "sales_promise.has_resident_allowance":
      case "sales_promise.resident_allowance":
      case "sales_promise.resident_allowance_period":
      case "sales_promise.epc_label":
      case "sales_promise.epc_score":
      case "sales_promise.reference":
      case "sales_promise.e_peil":
      case "sales_promise.notes":
      case "sales_promise.struck_by_red_line":
      case "sales_promise.asbestos_present":
      case "sales_promise.fuel_oil_tank_compliant":
      case "sales_promise.electricity_inspection_conform":
      case "equipment.kitchen.dishwasher":
      case "equipment.kitchen.air_extractor":
      case "equipment.kitchen.oven":
      case "equipment.kitchen.combi_oven":
      case "equipment.kitchen.microwave_oven":
      case "equipment.kitchen.steam_oven":
      case "equipment.kitchen.coffee_machine":
      case "equipment.kitchen.fridge":
      case "equipment.kitchen.stove":
      case "equipment.kitchen.stove_type":
      case "equipment.kitchen.sink_type":
      case "equipment.bathroom.baths":
      case "equipment.bathroom.showers":
      case "equipment.bathroom.sinks":
      case "equipment.bathroom.double_sinks":
      case "equipment.bathroom.guest_toilets":
      case "equipment.security.alarm_system":
      case "equipment.security.intercom":
      case "equipment.security.videophone":
      case "equipment.security.camera_surveillance":
      case "equipment.security.fire_detector":
      case "equipment.security.gated_access":
      case "wellness.pool":
      case "wellness.sauna":
      case "wellness.jacuzzi":
      case "wellness.steam_bath":
      case "other.home_automation":
      case "installation.roller_shutters.full_installation":
      case "installation.roller_shutters.material_other":
      case "connections.network_connection_available":
      case "connections.network_provider":
      case "connections.cable_television":
      case "construction.wall_finishing.material_other":
      case "construction.ceiling_finishing.material_other":
      case "construction.floor_finishing.material_other":
      case "sales_promise.electricity_certificate_available":
      case "sales_promise.electricity_certificate_date":
      case "sales_promise.asbestos_certificate_available":
      case "sales_promise.asbestos_certificate_date":
      case "sales_promise.oiltank_certificate_available":
      case "sales_promise.soil_certificate_available":
      case "sales_promise.servitude":
      case "sales_promise.building_permit":
      case "sales_promise.subdivision_permit":
      case "sales_promise.pre_emption_right":
      case "sales_promise.terrain_destination":
      case "sales_promise.summons":
      case "sales_promise.summons_type":
      case "sales_promise.protected_heritage":
      case "sales_promise.heritage":
      case "sales_promise.availability":
      case "sale.registration_rights_percent":
      case "sale.vat_system":
      case "sale.vat_percent":
      case "rooms":
      case "floor":
      case "floors":
      case "building.state":
        return ["HOUSE", "APPARTMENT", "COMMERCIAL"].includes(type);

      /**
       * Show when the type is HOUSE or COMMERCIAL
       * (Huis | Commercieel )
       */
      case "construction.roof.material":
      case "construction.roof.material_other":
      case "construction.roof.insulation_available":
      case "construction.roof.insulation_type":
      case "construction.roof.insulation_thickness":
        return ["HOUSE", "COMMERCIAL"].includes(type);

        /**
         * Show when the type is Land
         * (Bouwgrond, Parking)
         */
        return ["LAND", "PARKING"].includes(type);

      /**
       * Show when the type is Land
       * (Bouwgrond)
       */
      case "area.building_length":
      case "area.building_width":
      case "area.lateral_space":
      case "area.reversing_zone":
      case "area.in_allotment":
        return ["LAND"].includes(type);

      /**
       * Show when the type is appartement
       * (Appartement)
       */
      case "labels.syndic":
      case "building.syndicus_monthly_rate":
      case "building.syndicus_information":
        return ["APPARTMENT", "PARKING"].includes(type);

      /**
       * Show when the type is appartement
       * (Appartement)
       */
      case "other.elevator":
        return ["APPARTMENT"].includes(type);

      /**
       * Show when the type is Parking
       * (Parking)
       */
      case "area.parking_length":
      case "area.parking_width":
      case "area.parking_height":
        return ["PARKING"].includes(type);
    }

    console.warn(`[Hubr] ${key} is not set in the isAllowed method of the property`);

    return false;
  }

  callbackMaybeAddRooms() {
    if (this.plChar!.features.bedrooms) {
      const total = Number(this.plChar!.features.bedrooms);
      let rooms = (this.plChar!.rooms ?? []).filter((r) => r.type === "BEDROOM");
      if (total > rooms.length) {
        for (let i = rooms.length; i < total; i++) {
          this.plChar!.rooms?.push({
            type: "BEDROOM",
            title: "Slaapkamer",
            area: null,
            floor: null,
            is_livable_area: true,
            renovation_needed: false,
            renovation_year: "",
            note: "",
          });
        }
      }
    }

    if (this.plChar!.features.bathrooms) {
      const total = Number(this.plChar!.features.bathrooms);
      let rooms = (this.plChar!.rooms ?? []).filter((r) => r.type === "BATHROOM");
      if (total > rooms.length) {
        for (let i = rooms.length; i < total; i++) {
          this.plChar!.rooms?.push({
            type: "BATHROOM",
            title: "Badkamer",
            area: null,
            floor: null,
            is_livable_area: true,
            renovation_needed: false,
            renovation_year: "",
            note: "",
          });
        }
      }
    }
  }

  callbackMaybeChangeGarageCount() {
    if (this.plChar!.features.has_garage === false) {
      this.plChar!.features.garage_count = null;
    }
  }

  callbackMaybeChangeParkingCount() {
    if (this.plChar!.features.has_parking === false) {
      this.plChar!.features.inside_parking_count = null;
      this.plChar!.features.outside_parking_count = null;
    }

    if (this.plChar!.features.has_inside_parking === false) {
      this.plChar!.features.inside_parking_count = null;
    }

    if (this.plChar!.features.has_outside_parking === false) {
      this.plChar!.features.outside_parking_count = null;
    }
  }
}
